import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  baseSlice,
  exchangeRateSlice,
  forgotPasswordSlice,
  loginSlice,
  messageSlice,
  sessionSlice,
  userSlice,
} from "../constants/reducer";

export const store = configureStore({
  reducer: {
    session: sessionSlice.reducer,
    base: baseSlice.reducer,
    login: loginSlice.reducer,
    user: userSlice.reducer,
    message: messageSlice.reducer,
    exchangeRate: exchangeRateSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
